<template>
  <div class="me-breakPwd">
    <div class="me-breakPwd__header">
      <i class="me-icon-back" @click="$router.go(-1)"></i>
      <span class="me-breakPwd__header--title">{{$t('breakPwd')}}</span>
    </div>
    <div class="me-breakPwd__content">
      <div class="me-breakPwd__content--form">
            <div class="avatar type">
              <i
                :class="{ 'me-icon-envelope': true, active: registerType }"
                @click="registerType = !registerType"
              ></i>
              <i
                :class="{ 'me-icon-phone': true, active: !registerType }"
                @click="registerType = !registerType"
              ></i>
            </div>
            <ul>
              <li v-if="!registerType && !Verification" class="location" @click="showAddressTree = !showAddressTree">
                <i class="me-icon-location"></i>
                <i class="me-icon-right icon"></i>
                <span v-html="curCountry.text"></span>
              </li>
              
              <li :class="{ error: accountError, row: !registerType }" v-if="!registerType && !Verification">
                <i class="me-icon-phone"></i>
                <span v-if="curCountry.phonePrefix">+{{ curCountry.phonePrefix }}</span>
                <input type="text" v-model="phone" autofocus />
              </li>
              <li v-if="registerType && !Verification" :class="{error:emailError}">
                 <i class="me-icon-envelope"></i>
                <input type="text" v-model="email" autofocus />
              </li>
              <li v-if="Verification" :class={error:codeError}>
                 <i class="me-icon-password"></i>
                 <input type="text" style="flex:1" v-model="verCode" autofocus />
                 <span class="vercode-btn" @click="handlerGetVercodeAlign()" v-html="time> 0 ? time + 's' :$t('getverchatCode')"></span>
              </li>
              <li class="btn">
                <van-button
                  type="primary"
                  class="submit" 
                  :loading="loading"
                  @click="registerType ? handlerSendVerCodeForBtn() : handlerPhoneClick()"
                >
                  <i class="me-icon-check"></i>
                </van-button>
              </li>
            </ul>
          </div>
    </div>
     <van-popup
      v-model="showAddressTree"
      position="left"
      class="me-login--popup"
      :style="{ height: '100%', width: '90%' }"
    >
      <van-tree-select
        :items="regions"
        :active-id.sync="curCountry.value"
        :main-active-index.sync="regionIndex"
        color="#33cc66"
        height="100%"
        @click-item="handlerChangeCountry"
      />
    </van-popup>
  </div>
</template>
<script>
import {
  fullRegion,
  sendValidateCode,
  checkValidateCode
} from "@/api/webApi";
export default {
  name: "breakPwd",
  data() {
    return {
      active:'1',
      registerType: true,
      showAddressTree: false,
      curCountry: {},
      language: "",
      accountError: false,
      loading: false,
      account:'',
      regions:[],
      regionIndex:0,
      Verification:false,
      verCode:'',
      codeError:false,
      email:'',
      emailError:false,
      phone:'',
      time:60,
      clicked: false
    };
  },
   watch: {
    registerType: {
      handler(val) {
        this.accountError = false;
        this.account =  "";
        this.Verification = this.clicked = false
        if (!val && !this.regions.length) {
          this.handlerGetFullRegion();
        }
      },
      deep: true
    }
  },
  created() {
  },
  methods: {
    handlerGetVercodeAlign(){
      this.handlerSendVerCode()
    },
    handlerPhoneClick(){
      if(this._checkPhone() && !this.clicked){
        this.handlerSendVerCode()
        this.clicked = true
      }else{
         this.handlerCheckValidateCode()
      }
    },
    handlerSendVerCodeForBtn(){
        if(!this.Verification)
          this._checkEmail()
        else
          this.handlerCheckValidateCode()
    },    
    async handlerCheckValidateCode(){
      const params = {
        code:this.verCode,       
        language:localStorage.getItem('langCode') || 1,
        email: this.registerType ? this.email : '',
        phone:!this.registerType ? this.phone : '',
        countryCode: !this.registerType ? this.curCountry.phonePrefix : '',
      }
      const result = await checkValidateCode(params)
      if(result && result.code === 200){
        this.codeError = false
        this.$router.push({name:'resetPwd',query:{userAuth: this.registerType ? this.email : this.phone,code:this.verCode,countryCode:this.curCountry.phonePrefix}})
      }else
        this.codeError = true
    },
   async handlerSendVerCode(){
     const params = {
       language:localStorage.getItem('langCode') || 1,
       email: this.registerType ? this.email : '',
       phone:!this.registerType ? this.phone : '',
      countryCode: !this.registerType ? this.curCountry.phonePrefix : '',
     }
     let timeer = null
     const result = await sendValidateCode(params)
     if(result && result.code === 200)
     {
       if(this.time<=0) this.time = 60
       this.Verification = true
       timeer = setInterval(()=>{
          if(this.time>=0)
            this.time--
        },1000)
     }else{
       this.time = 60
       clearInterval(timeer)
     }
    },
    _checkPhone(){
      if(this.phone){
        this.Verification = true
        return true
      }else{
        return false
      }
        
    },
    _checkEmail(){
      var regEmail = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
      if (!regEmail.test(this.email)) {
          this.emailError = true
      }else{
        this.emailError = false
        this.handlerSendVerCode()
      }
    },
    handlerChangeCountry(data) {
      this.curCountry = data;
      this.countryId = data.value;
      this.showAddressTree = !this.showAddressTree;
    },
     async handlerGetFullRegion() {
      let params = { language: this.language };
      const result = await fullRegion(params).catch(e => {});
      if (result) {
        this.regions = result.data.data.map(item => {
          return {
            text: item.regionName,
            value: item.regionId,
            children: item.countries.map(el => {
              return {
                text: el.countryName,
                id: el.countryId,
                value: el.countryCode,
                phonePrefix: el.phonePrefix
              };
            })
          };
        });
        // this.regions.unshift({ text: this.$t("all"), value: "" });
        this.regions[0].children.some(item => {
          if (item.value == "37") this.curCountry = item;
        });
      }
    },
  }
};
</script>
<style lang="stylus">
@import '~styles/utils/index';
.me-login--popup .van-tree-select .van-sidebar-item--select{
  border-color #33cc66 !important
}
.vercode-btn{
  font-size 12px;
}
.my-icon{
  font-size 28px;
  padding .9rem;
  border-radius:5rem;
  background #e5e5e5
  width:30px;
  height:30px
  &.active{
    color #33cc66
  }
}
+b(me-breakPwd) {
  background: #ffffff;

  +e(header) {
    height: 54px;
    line-height: 54px;
    padding: 0 0.9rem;

    .me-icon-back {
      float: left;
    }
  }
  +e(content){
    // min-height:94vh;
    margin-top:5rem;
    display:flex;
    align-items:center;
    justify-content:center;
    // .tab-title{
    //   width: 80%;
    //   display flex
    //   flex-flow row
    //   justify-content space-around
    // }
    +m(form) {
      width:90%;
      margin 0 auto;
      display: flex;
      flex-flow: column;
      padding: 0.5rem 0;

      .avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.5rem;

        i {
          font-size: 36px;
          background: #f5f5f5;
          text-align: center;
          width: 70px;
          height: 70px;
          line-height: 70px;
          border-radius: 70px;
          color: #c5c5c5;
          margin-right: 0.5rem;

          &:last-child {
            margin-right: 0;
          }

          &.active {
            color: #33cc66;
          }
        }

        &.type {
          i {
            width: 65px;
            height: 65px;
            line-height: 65px;
            border-radius: 65px;
            font-size: 24px;
            margin-right: 1.5rem;

            &:last-child {
              margin: 0;
            }
          }
        }
      }

      .loginOtherWay {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        margin: 0.9rem 0;

        i {
          font-size: 28px;

          &.me-icon-qq {
            color: #04c6f1;
          }

          &.me-icon-weibo {
            color: #fb6e65;
          }

          &.me-icon-wechat {
            color: #33cc66;
          }

          &.me-icon-linkedin {
            color: rgb(0, 123, 181);
          }

          &.me-icon-facebook {
            color: rgb(60, 90, 153);
          }

          &.me-icon-twitter {
            color: rgb(85, 172, 238);
          }
        }
      }

      ul {
        margin: 1rem 0;

        li {
          padding: 0.9rem 0;
          line-height: 50px;
          position: relative;
          border-bottom: 1px solid #f5f5f5;
          display: flex;
          flex-flow: row;

          &.error {
            border-color: red;
          }

          &:last-child {
            border: 0;
          }

          &.row {
            span {
              position: absolute;
              left: 30px;
            }

            input {
              text-indent: 4.5rem;
            }
          }

          &.location {
            display: flex;
            flex-flow: row;

            i {
              position: relative;
              margin-right: 0.5rem;

              &.icon {
                position: absolute;
                right: 0;
                font-size: 16px;
              }
            }
          }

          i {
            position: absolute;
            font-size: 22px;
            color: #666666;

            &.forget {
              right: 0;
            }
          }

          input {
            font-size: 16px;
            color: #666666;
            line-height: 50px;
            border: 0;
            width: 100%;
            text-indent: 1.9rem;
          }

          &.btn {
            display: flex;
            flex-flow: column;

            .submit {
              border-radius: 2rem;
              line-height: auto;

              i {
                font-size: 26px;
                position: relative;
              }
            }

            i {
              font-size: 18px;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}
</style>