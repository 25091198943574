var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "me-breakPwd" },
    [
      _c("div", { staticClass: "me-breakPwd__header" }, [
        _c("i", {
          staticClass: "me-icon-back",
          on: {
            click: function($event) {
              return _vm.$router.go(-1)
            }
          }
        }),
        _c("span", { staticClass: "me-breakPwd__header--title" }, [
          _vm._v(_vm._s(_vm.$t("breakPwd")))
        ])
      ]),
      _c("div", { staticClass: "me-breakPwd__content" }, [
        _c("div", { staticClass: "me-breakPwd__content--form" }, [
          _c("div", { staticClass: "avatar type" }, [
            _c("i", {
              class: { "me-icon-envelope": true, active: _vm.registerType },
              on: {
                click: function($event) {
                  _vm.registerType = !_vm.registerType
                }
              }
            }),
            _c("i", {
              class: { "me-icon-phone": true, active: !_vm.registerType },
              on: {
                click: function($event) {
                  _vm.registerType = !_vm.registerType
                }
              }
            })
          ]),
          _c("ul", [
            !_vm.registerType && !_vm.Verification
              ? _c(
                  "li",
                  {
                    staticClass: "location",
                    on: {
                      click: function($event) {
                        _vm.showAddressTree = !_vm.showAddressTree
                      }
                    }
                  },
                  [
                    _c("i", { staticClass: "me-icon-location" }),
                    _c("i", { staticClass: "me-icon-right icon" }),
                    _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.curCountry.text) }
                    })
                  ]
                )
              : _vm._e(),
            !_vm.registerType && !_vm.Verification
              ? _c(
                  "li",
                  {
                    class: { error: _vm.accountError, row: !_vm.registerType }
                  },
                  [
                    _c("i", { staticClass: "me-icon-phone" }),
                    _vm.curCountry.phonePrefix
                      ? _c("span", [
                          _vm._v("+" + _vm._s(_vm.curCountry.phonePrefix))
                        ])
                      : _vm._e(),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.phone,
                          expression: "phone"
                        }
                      ],
                      attrs: { type: "text", autofocus: "" },
                      domProps: { value: _vm.phone },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.phone = $event.target.value
                        }
                      }
                    })
                  ]
                )
              : _vm._e(),
            _vm.registerType && !_vm.Verification
              ? _c("li", { class: { error: _vm.emailError } }, [
                  _c("i", { staticClass: "me-icon-envelope" }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.email,
                        expression: "email"
                      }
                    ],
                    attrs: { type: "text", autofocus: "" },
                    domProps: { value: _vm.email },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.email = $event.target.value
                      }
                    }
                  })
                ])
              : _vm._e(),
            _vm.Verification
              ? _c("li", { class: { error: _vm.codeError } }, [
                  _c("i", { staticClass: "me-icon-password" }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.verCode,
                        expression: "verCode"
                      }
                    ],
                    staticStyle: { flex: "1" },
                    attrs: { type: "text", autofocus: "" },
                    domProps: { value: _vm.verCode },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.verCode = $event.target.value
                      }
                    }
                  }),
                  _c("span", {
                    staticClass: "vercode-btn",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.time > 0 ? _vm.time + "s" : _vm.$t("getverchatCode")
                      )
                    },
                    on: {
                      click: function($event) {
                        return _vm.handlerGetVercodeAlign()
                      }
                    }
                  })
                ])
              : _vm._e(),
            _c(
              "li",
              { staticClass: "btn" },
              [
                _c(
                  "van-button",
                  {
                    staticClass: "submit",
                    attrs: { type: "primary", loading: _vm.loading },
                    on: {
                      click: function($event) {
                        _vm.registerType
                          ? _vm.handlerSendVerCodeForBtn()
                          : _vm.handlerPhoneClick()
                      }
                    }
                  },
                  [_c("i", { staticClass: "me-icon-check" })]
                )
              ],
              1
            )
          ])
        ])
      ]),
      _c(
        "van-popup",
        {
          staticClass: "me-login--popup",
          style: { height: "100%", width: "90%" },
          attrs: { position: "left" },
          model: {
            value: _vm.showAddressTree,
            callback: function($$v) {
              _vm.showAddressTree = $$v
            },
            expression: "showAddressTree"
          }
        },
        [
          _c("van-tree-select", {
            attrs: {
              items: _vm.regions,
              "active-id": _vm.curCountry.value,
              "main-active-index": _vm.regionIndex,
              color: "#33cc66",
              height: "100%"
            },
            on: {
              "update:activeId": function($event) {
                return _vm.$set(_vm.curCountry, "value", $event)
              },
              "update:active-id": function($event) {
                return _vm.$set(_vm.curCountry, "value", $event)
              },
              "update:mainActiveIndex": function($event) {
                _vm.regionIndex = $event
              },
              "update:main-active-index": function($event) {
                _vm.regionIndex = $event
              },
              "click-item": _vm.handlerChangeCountry
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }